
import { defineComponent, computed,ref, toRefs, watch } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import VuePdfEmbed from 'vue-pdf-embed'


export default defineComponent({
  name: "WebViewer",
    components:{
        DynamicContentModal,
        VuePdfEmbed
    },
    props: { 
      base64: { 
        type: String,
        required: true 
        },
      titulo: {
        type: String,
        required: true 
      } 
    },
    data() {
      return {
         annotationIconsPath: '/node_modules/pdfjs-dist/web/images/'
      }
    },
    setup(props) {
    let pdf = ref("");

    const { base64 } = toRefs(props);

    watch(base64, (value) => {
      pdf.value = value;
    })
    
    const base64ToBlob = (base64) => {
      console.log(base64);
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: 'application/pdf' });
    };

    const downloadFile = () => {
      var a = document.createElement("a");
      a.href = base64.value; 
      a.download = props.titulo; 
      a.click();
    }

    return {
      downloadFile,
      base64ToBlob,
      pdf,
      pdfbase64: computed(() => { return pdf.value })
    }
    }
})

