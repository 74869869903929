export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "BUTTONS": {
          "CONTINUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])},
          "CANCEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
          "DOWLOAD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])}
        }
      }
    }
  })
}
