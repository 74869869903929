
import { computed, defineComponent, reactive, ref, onMounted } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import { useRouter } from "vue-router";
import SwalMessageService from "@/core/services/SwalMessageService";
import MessageService from "@/core/services/interfaces/MessageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { translate } from "@/core/plugins/i18n";
import TableReturnSaleModule from "@/store/modules/salesreturn/modules/table/index"
import SaleReturnModule from "@/store/modules/salesreturn/modules/new-salereturn/index"
import { getModule } from "vuex-module-decorators";
import { Field, Form } from "vee-validate";
import * as Validations from "yup";
import  PDFViewer from "@/views/cashregister/components/PDFViewer.vue"
import { debounce } from "lodash";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
//import SelectFilterRemote from "@/components/forms/SelectFilterRemote.vue"

export default defineComponent({
    components:{
        Field
        , Form
        , ModuleMaster
        , Datatable
        , Datarow
        , PDFViewer
        //, SelectFilterRemote
    },
    setup(context){

        const moduleTable = getModule(TableReturnSaleModule);
        const moduleSaleReturn = getModule(SaleReturnModule);
        const openModalProductForm = ref(HTMLButtonElement);

        const showSearchProduct = ref(false);
        const showExtras = ref(false);
        const router = useRouter();
        const messageService: MessageService = new SwalMessageService();
        const formSearch = ref();

        const openAuthorizedPDF = ref(HTMLButtonElement); 

        const validationSchema = Validations.object().shape({
            rfc: Validations.string().nullable().default(null).label("rfc"),
            folio: Validations.string().nullable().default(null).label("folio"),
            name: Validations.string().nullable().default(null).label("name")
        });

        moduleTable.SEARCH_TABLE_DATA();
         /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },   
            { PropName: "saleId", HeadLabel: "Venta", Type: "text", VisibleInGrid: false }, 
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },    
            { PropName: "rfc", HeadLabel: "RFC", Type: "text", VisibleInGrid: true }, 
            { PropName: "customer", HeadLabel: "Cliente", Type: "text", VisibleInGrid: true },   
            { PropName: "total", HeadLabel: "Total", Type: "moneda", VisibleInGrid: true },  
            { PropName: "stage", HeadLabel: "Estatus", Type: "text", VisibleInGrid: true }, 
            { PropName: "saleReturnAtF", HeadLabel: "Fecha Devolución", Type: "text", VisibleInGrid: true }, 
        ]);
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar Devolución",
            titleNewCrudAction: "Agrega Devolución",
        });
       
       //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }

        const searchItems = async(text) => {
             moduleTable.UPDATE_FILTERS({
                 invoice: text
             });
        }

        const renderRows = computed(() => {
            return formatToDatatable(moduleTable.records, configTable);           
        });

        const handleCrudAction = async (action: string | number = "N", id: string) => {       
            let saleId = "";
            if(action === "M")
            {
                const sale = records.value.find(ir => ir.id === id);
                if(sale)
                {
                    if(sale.saleId != "")
                    {
                        saleId = sale.saleId;
                    }
                }  
            }
            moduleSaleReturn.RESET_SALERETURN();
            switch(action){
                case 'GD':
                     Swal.fire({
                        title: 'Motivo de devolución',
                        input: 'text',
                        inputAttributes: {
                            autocapitalize: 'off'
                        },
                        showCancelButton: true,
                        confirmButtonColor: '#000000',
                        cancelButtonColor: '#7E829',
                        confirmButtonText: 'Guardar'
                        }).then((result) => {
                        if (result.isConfirmed) {
                            messageService.success(translate('MESSAGES.RETURNSALES'));
                        }});
                break;
                case "EA":
                    Swal.fire({
                                title: '¿Está seguro enviar la devolución al almacen?',
                                text: "Se procederá a enviar la solicitud y no se podra editar",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#000000',
                                cancelButtonColor: '#7E829',
                                confirmButtonText: 'Si, enviar'
                            }).then((result) => {
                            if (result.isConfirmed) {
                                moduleTable.APPROVE_SALERETURN(id);
                            }});
                    break;
                case "N":
                    // setCurrentPageBreadcrumbs( translate('BREADCRUMB.NEWSALES'), [translate('BREADCRUMB.MODULES'), { name: translate('BREADCRUMB.SALES'), to: "sales" }]);
                    moduleSaleReturn.RESET_SALERETURN();
                    router.push({
                            name: "/salesReturns/saleReturn",
                            params: {
                                action: "new"
                            }
                        });
                    break;
                case "M":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    //.RESET_SALERETURN();
                    moduleSaleReturn.SEARCH_INFO_SALEDETAILS(saleId);//"49abe690-bf05-4500-86ab-dd0c7aa727da");
                    moduleSaleReturn.SEARCH_INFO_SALERETURN(id);
                    router.push({
                        name: "/salesReturns/saleReturn",
                        params: {
                            action: "edit"
                        }
                    });
                    break;
                case "V":
                    // setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALES'), [translate('BREADCRUMB.MODULES')]);
                    //moduleSaleReturn.RESET_SALERETURN();
                    moduleSaleReturn.SEARCH_INFO_SALERETURN(id);
                    router.push({
                        name: "/salesReturns/saleReturn",
                        params: {
                            action: "view"
                        }
                    });
                    break;   
                case "IMPAUT":
                    getAuthorizedPDF(id);
                    break;    
            }
        }

        const openModalProduct  = (concept) => {
         if(openModalProductForm.value) {
            (openModalProductForm.value as unknown as HTMLButtonElement).click();
          }
        }

        const searchFilter = (data: any) => {
            moduleTable.UPDATE_FILTERS({
                invoice: data.folio
                , rfc: data.rfc
                , Name: data.name
                , lstSaleReturnStatus: data.statusId
            });
        }

        const showOptionEdit = (id: string) => {
            const sale = records.value.find(ir => ir.id === id);
            let responseOption = false;
            if(sale)
            {
                if(sale.saleReturnStatus == 0){
                    responseOption = true;
                }
            }
            return responseOption;
        }

        const showOptionSend = (id: string) => {
            const sale = records.value.find(ir => ir.id === id);
            let responseOption = false;
            if(sale)
            {
                if(sale.saleReturnStatus == 0){
                    responseOption = true;
                }
            }
            return responseOption;
        }

        const showOptionImpNote = (id: string) => {
            const sale = records.value.find(ir => ir.id === id);
            let responseOption = false;
            if(sale)
            {
                if(sale.saleReturnStatus == 2){
                    responseOption = true;
                }
            }
            return responseOption;
        }

        const getAuthorizedPDF = async(id:string) => {
            moduleSaleReturn.GET_AUTHORIZEDPDF(id).then(r => {
                if(openAuthorizedPDF.value) {
                    (openAuthorizedPDF.value as unknown as HTMLButtonElement).click();
                }   
            });
        }

        const pdf = computed(() => moduleSaleReturn.pdf);

        const records = computed(() => moduleTable.records);
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        /*const statusOptions = computed(() => 
                                {
                                    return [{id: 0, name: "Creado"}, 
                                            {id: 1, name:"Enviado a almacén"},
                                            {id: 2, name:"Aprobado"},
                                            {id: 3, name:"Finalizado"},
                                            {id: 4, name:"Rechazado"},
                                            {id: 5, name:"Cancelado"}]
                                })*/
        onMounted(() => {
            /*formSearch.value.setValues({
                 statusId: {0: 0, 1:2}
            });*/
            
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.SALESRETURNS'), [translate('BREADCRUMB.MODULES')]);
        });

        return {
            headers
            , renderRows
            , labels
            , records
            , totalRecords
            , sizePage
            , showSearchProduct
            , showExtras
            , formSearch
            , openModalProductForm
            , handleCrudAction
            , handleChangePage
            //, validationSchema
            , searchFilter
            , showOptionEdit
            , showOptionSend
            , showOptionImpNote
            , getAuthorizedPDF
            , openAuthorizedPDF
            , pdf
            , searchItems
            //, statusOptions
            //, defaultOptionStatus
         }
    }
})
