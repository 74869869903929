import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3 text-center" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "d-flex justify-content-end mb-1" }
const _hoisted_4 = { class: "svg-icon svg-icon-gray-100 svg-icon-3" }
const _hoisted_5 = {
  class: "scroll-y me-n7 pe-7 border border-dark mb-1 rounded",
  id: `viewPDF_scroll`,
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#viewPDF_header",
  "data-kt-scroll-wrappers": "#viewPDF_scroll",
  "data-kt-scroll-offset": "200px"
}
const _hoisted_6 = {
  class: "btn btn-lg btn-primary float-end close",
  "data-bs-dismiss": "modal"
}
const _hoisted_7 = { class: "indicator-label" }
const _hoisted_8 = { class: "svg-icon svg-icon-3 ms-2 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_vue_pdf_embed = _resolveComponent("vue-pdf-embed")!
  const _component_DynamicContentModal = _resolveComponent("DynamicContentModal")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_DynamicContentModal, {
      targetId: 'viewPDF',
      width: 900
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.titulo), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "btn btn-sm btn-primary-blue float-end",
            "data-bs-toggle": "tooltip",
            "data-bs-placement": "bottom",
            title: "Descargar Informe",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.downloadFile('P')))
          }, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/files/fil021.svg" })
            ]),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("BUTTONS.DOWLOAD")), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_vue_pdf_embed, {
            "image-resources-path": _ctx.annotationIconsPath,
            source: _ctx.pdf,
            height: "200",
            width: "800"
          }, null, 8, ["image-resources-path", "source"])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("button", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t("BUTTONS.CONTINUE")), 1),
              _createElementVNode("span", _hoisted_8, [
                _createVNode(_component_inline_svg, { src: "icons/duotune/arrows/arr064.svg" })
              ])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}